/*global $*/
/*global document*/
/*global window*/

$(document).ready(function() {

  // Animate elements on scroll
  $(document).scroll(function() {

      var userPosition = $(this).scrollTop();

      if (document.getElementById("about")) {
        var aboutSection = Math.abs($('#about').position().top - $(window).height()/2);
        if(userPosition > aboutSection) {
          $("#about .animation-top").addClass("fade-from-top");
          $("#about .animation-left").addClass("fade-from-left");
          $("#about .animation-right").addClass("fade-from-right");
        }
      }

      if (document.getElementById("services")) {
        var servicesSection = Math.abs($('#services').position().top - $(window).height()/2);
        if(userPosition > servicesSection) $("#services .animation-left").addClass("fade-from-left");
      }

      if (document.getElementById("facilities")) {
        var facilitiesSection = Math.abs($('#facilities').position().top - $(window).height()/2);
        if(userPosition > facilitiesSection) {
          $("#facilities .animation-left").addClass("fade-from-left");
          $("#facilities .animation-right").addClass("fade-from-right");
        }
      }
  });

});
